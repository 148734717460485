import styled from 'styled-components';

import { Heading } from 'combinezone/core';

export const EntitiesNotFoundOut = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 112px;
  & span {
    text-align: center;
  }
`;

export const EntitiesNoData = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-start;

  ${Heading} {
    margin: 29.35vh 0 0;
    font-size: 24px;
  }
`;