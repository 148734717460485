import styled from 'styled-components';

import {
  ButtonPrimary,
  Checkbox,
  Factoid,
  Heading,
  Input,
  InputContainer,
  PasswordInput,
  Textarea,
  TextLink,
  TextWrapper,
} from 'combinezone/core';

export const AuthLayoutOut = styled.div`
  display: grid;
  height: auto;
  grid-auto-columns: 1fr;
  gap: 0;

  grid-template-columns: 1fr;
  grid-auto-rows: minmax(min-content, max-content);

  grid-template-areas:
    'info'
    'outlet'
    'footer';

  @media only screen and (min-width: 1024px) {
    height: 100vh;
    grid-template-columns: minmax(482px, 40%) 1fr;
    grid-template-rows: 1fr 115px;

    grid-template-areas:
      'info outlet'
      'footer outlet';
  }
`;

export const InfoOut = styled.div`
  grid-area: info;
  background: #141a20;
`;

export const InfoIn = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: auto;
  padding: 0 0 0 80px;
  margin: 72px 40px;
  box-sizing: border-box;
  & ${Heading} {
    opacity: 0.6;
  }

  @media only screen and (min-width: 1024px) {
    width: auto;
    margin: 72px 40px 32px;
  }
`;

export const InfoIcon = styled.img`
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  left: 0;
  top: 0;
`;

export const InfoList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  & ul {
    margin: 0;
    padding: 0;
    color: #ffffffff;
    & li {
      margin: 8px 0 0;
    }
  }

  @media only screen and (min-width: 1024px) {
    margin: 0 0 0;
  }
`;

export const BoldFactoid = styled(Factoid)`
  font-weight: 700;
`;

export const AuthLayoutIn = styled.div`
  grid-area: outlet;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  background: ${({ theme }) => theme.components.layout.colors.background};
`;

export const FooterOut = styled.div`
  grid-area: footer;
  background: #141a20;
  border-top: 1px solid #ffffff4d;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 32px 24px;
  height: 116px;
  box-sizing: border-box;
  gap: 0 24px;
  @media only screen and (min-width: 1024px) {
    grid-template-columns: 130px 1fr 100px;
  }
`;

export const FooterIcon = styled.img`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: 0.3;
`;

export const LayoutWithTabsOut = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: 148px auto 216px;

  @media only screen and (min-width: 1024px) {
    margin: 120px auto 0;
  }
`;

export const FormControlsRow = styled.div`
  margin-top: 32px;
`;

export const FooterInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  ${TextWrapper}:nth-child(1) {
    opacity: 0.4;
    color: #fff;
  }
  ${TextWrapper}:nth-child(2) {
    color: #999999;
  }
`;

export const TabsOut = styled.div`
  & > div {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;

    & > button {
      border: none;
      &:hover {
        background-color: initial;
      }
    }

    & > .TabActive {
      box-shadow: inset 0 -4px 0 -1px #163bff;
    }
  }
`;

export const LayoutWithBackButtonOut = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  & > button {
    position: absolute;
    top: 56px;
    left: 24px;
  }
`;

export const LayoutWithBackButtonIn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 240px auto 216px;
  box-sizing: border-box;

  align-items: center;
  justify-content: center;
`;

export const StyledButtonPrimary = styled(ButtonPrimary)`
  background: #163bff;
  width: 100%;
  &:hover {
    background: #007aff;
  }

  &[aria-disabled] {
    background-color: #9c9fa3;
  }
`;

export const StyledInput = styled(Input)`
  & ${InputContainer} {
    &:focus {
      border: none;
      box-shadow: inset 0 0 0 1px #163bff, 0 0 0 2px #163bff7e;
    }
    &[data-invalid],
    &[aria-invalid] {
      border: 1px solid
        ${({ theme }) => theme.components.input.colors.border.critical};
    }

    &:hover:not(:focus) {
      opacity: initial;
      border-color: #163bff;
    }
  }
`;

export const StyledPasswordInput = styled(PasswordInput)`
  & ${InputContainer} {
    &:focus {
      border: none;
      box-shadow: inset 0 0 0 1px #163bff, 0 0 0 2px #163bff7e;
    }
    &[data-invalid],
    &[aria-invalid] {
      border: 1px solid
        ${({ theme }) => theme.components.input.colors.border.critical};
    }

    &:hover:not(:focus) {
      opacity: initial;
      border-color: #163bff;
    }
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  &.active {
    &,
    &:hover {
      svg {
        color: #163bff;
      }
    }
  }
`;
export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 8px;
`;

export const FormRows = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: 24px;
`;

export const StyledLink = styled(TextLink)`
  display: block;
  width: max-content;
  margin: 0 auto;
  text-decoration: none;
  border-bottom: 1px dashed;
  &,
  &:hover {
    color: #163bff;
  }
`;

export const StyledTextarea = styled(Textarea)`
  &:focus {
    border: none;
    box-shadow: inset 0 0 0 1px #163bff, 0 0 0 2px #163bff7e;
  }
  &[data-invalid],
  &[aria-invalid] {
    border: 1px solid
      ${({ theme }) => theme.components.input.colors.border.critical};
  }

  &:hover:not(:focus) {
    opacity: initial;
    border-color: #163bff;
  }
`;

export const FreeTestBadge = styled.div`
  background: #7ac360;
  padding: 4px 8px 4px 8px;
  width: fit-content;
  ${TextWrapper} {
    color: #ffffff;
  }
  position: relative;
  left: -8px;
`;
