// @ts-nocheck
import { createClient } from 'graphql-ws';
import ConnectionHandler from 'relay-connection-handler-plus';
import { Environment, Observable, RecordSource, Store } from 'relay-runtime';
import {
  authMiddleware,
  ConcreteBatch,
  RelayNetworkLayer,
  RelayNetworkLayerOpts,
  SubscribeFunction,
  uploadMiddleware,
  urlMiddleware,
  Variables,
  MiddlewareSync
} from 'react-relay-network-modern';

import { RelayDefaultHandlerProvider } from 'relay-runtime/lib/handlers/RelayDefaultHandlerProvider';

import AuthService from './Services/AuthService';

const ignoreTimeoutMiddleware: MiddlewareSync = (next) => (req) => {
  if (req.cacheConfig?.metadata?.ignoreTimeout) {
    const originalUrl = req.fetchOpts.url || '/api/v1/graphql';
    req.fetchOpts.url = `${originalUrl}${originalUrl.includes('?') ? '&' : '?'}ignore-timeout=true`;
  }

  return next(req);
};

// Relay passes a "params" object with the query name and text. So we define a helper function
// to call our fetchGraphQL utility with params.text.
const middlewares = [
  urlMiddleware({
    url: '/api/v1/graphql',
    method: 'POST',
    headers: () => ({
      Authorization: AuthService.accessToken,
    }),
  }),
  ignoreTimeoutMiddleware,
  uploadMiddleware(),
  authMiddleware({
    token: () => AuthService.accessToken,
    header: 'Authorization',
    prefix: 'Bearer ',
    tokenRefreshPromise: () =>
      AuthService.refreshTokens().catch(() => {
        let returnUrl = '';
        if (window.location.pathname.trim().length > 1) {
          returnUrl = `?returnUrl=${encodeURIComponent(
            window.location.pathname + window.location.search,
          )}`;
        }
        window.location.replace(`/auth/login${returnUrl}`);
        return Promise.reject('Authorization failed');
      }),
  }),
];



const wsUrl =
  (process.env.NODE_ENV === 'production' &&
    window.location.origin === process.env.REACT_APP_PROD_URL
    ? process.env.REACT_APP_WS_PROD_URL
    : process.env.REACT_APP_WS_DEV_URL) ||
  'ws://bp.k8s.dev.lti.bi.zone/api/v1/graphqlws';

const subscribe = (
  operation: ConcreteBatch,
  variables: Variables,
): SubscribeFunction | any => {
  const wsClient = createClient({
    url: wsUrl,
    connectionParams: {
      authorization: AuthService.accessToken
        ? `Bearer ${AuthService.accessToken}`
        : {},
    },
  });

  return Observable.create((sink) => {
    if (!operation?.text) {
      return sink.error(new Error('Operation text cannot be empty'));
    }
    wsClient.subscribe(
      {
        operationName: operation.name,
        query: operation.text,
        variables,
      },
      sink,
    );

    return () => {
      wsClient.dispose();
    };
  });
};

const options: RelayNetworkLayerOpts = {
  subscribeFn: subscribe,
};

export const network = new RelayNetworkLayer(middlewares, options);

const store = new Store(new RecordSource());

const handlerProvider = (handle: string) => {
  switch (handle) {
    case 'connection':
      return ConnectionHandler;
    default:
      return RelayDefaultHandlerProvider(handle);
  }
};

// Export a singleton instance of Relay Environment configured with our network function:
export default new Environment({
  handlerProvider,
  network,
  store,
});
