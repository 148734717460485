
export const excludedUsers: string[] = [
    'bp_connector',
    'bp_state_synchronizer',
    'bp_data_leak',
    'bp_screenshoter',
    'bp_block_checker',
    'bp_migration',
    'state_synchronizer',
    'migration_user',
    '<dataleakfinder>',
    '<sinkholefinder>',
    'bpt_antiphishing',
    'bpt_code_rover_v3',
    'bpt_mos_cortex',
    'tt_dorks_connector',
    'soc_portal_gti',
    'bpt_phishhunt_bot',
    'bpt_ti_mail_bot',
    'bpt_yandex_safe_browsing_connector',
    'bpt_tg_alert_bot',
    'bpt_ias',
    'bpt_apks_monitor',
    'mlg_connector',
    'bpt_leak_zone_bot',
    'bpt_fish_and_card',
    'bpt_yandex_direct_monitor',
    'bpt_markeplaces_ads_monitor'
];