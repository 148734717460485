import { FC, useEffect } from 'react';

import { EventLogParams } from '#Modules/EventLog/types';
import { usePaginationFragment } from 'react-relay/hooks';
import { EventLogLoaderQuery$data } from '#__artifacts/EventLogLoaderQuery.graphql';
import { EventLogPaginationQuery } from '#__artifacts/EventLogPaginationQuery.graphql';
import { EventLogConnectionFragment$key } from '#__artifacts/EventLogConnectionFragment.graphql';

import EventLogGrid from '../EventLogGrid/EventLogGrid';
import { fragmentEventLog } from './EventLogConnection.graphql';

type EventLogConnectionProps = {
  queryRef: EventLogLoaderQuery$data;
  setShowFilter: (value: boolean) => void;
  params: EventLogParams;
};

const EventLogConnection: FC<EventLogConnectionProps> = ({
  queryRef,
  setShowFilter,
  params,
}) => {
  const { data, hasNext, loadNext, isLoadingNext } = usePaginationFragment<
    EventLogPaginationQuery,
    EventLogConnectionFragment$key
  >(fragmentEventLog, queryRef);

  const { events, user } = params;

  useEffect(() => {
    const hasFilters = !!(user || events?.length);
    const hasData = data?.history?.edges?.length !== 0;

    setShowFilter(hasFilters || hasData);
  }, [data, events, setShowFilter, user]);

  return (
    <EventLogGrid
      data={data}
      hasNext={hasNext}
      loadNext={loadNext}
      isLoadingNext={isLoadingNext}
      params={params}
    />
  );
};

export default EventLogConnection;
EventLogConnection.displayName = 'EventLogConnection';
