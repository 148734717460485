import { useServicesMatch } from '#Hooks/useServicesMatch';

import { ServicePages } from '../Models/common';

export const useWorkspace = () => {
  let workspace = '';

  const { isResources, isLinks, isLeaks, isMedia, isBankCard, isIpRange, isTaxpayerId } = useServicesMatch();

  if (isResources) workspace = ServicePages.Resources;
  else if (isLinks) workspace = ServicePages.Links;
  else if (isLeaks) workspace = ServicePages.Leaks;
  else if (isMedia) workspace = ServicePages.Media;
  else if (isBankCard) workspace = ServicePages.BankCard;
  else if (isIpRange) workspace = ServicePages.IpRange;
  else if (isTaxpayerId) workspace = ServicePages.TaxpayerId;

  return workspace;
};
