import React from 'react';

import { RouteObject } from 'react-router/dist/lib/context';

import { Workspaces } from '../../../../Models/common';
import ErrorStub from '../../../../Components/ErrorStub';
import SelectionProvider from '../../../../Components/SelectionProvider';

export const BankCardPageRoot = `${Workspaces.Pilot}/bankcard`;

const BankCardPage = React.lazy(() => import('.'));

const BankCardPageRoute: RouteObject = {
    path: `${BankCardPageRoot}/*`,
    element: (
        <SelectionProvider>
            <BankCardPage />
        </SelectionProvider>
    ),
    errorElement: <ErrorStub />,
};

export default BankCardPageRoute;