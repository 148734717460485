/**
 * @generated SignedSource<<2356cb865a5fa5a0797e2668cc1f9a3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EntitySource = "Bizone" | "BizoneAndClient" | "Client";
export type Priority = "High" | "Low" | "Medium";
export type Service = "Database" | "FraudulentResource" | "LimitedAccess" | "MassMedia" | "MobileApplication" | "Pilot" | "SharingPlatform" | "SocialAccount" | "SocialMedia";
export type State = "BlockPending" | "Blocked" | "Checked" | "Correcting" | "CorrectionDenied" | "DelegationRestored" | "Edited" | "InCommunication" | "InProgress" | "Irrelevant" | "Legitimate" | "Monitoring" | "New" | "NoContent" | "NoViolations" | "Settled" | "Unknown" | "Violation" | "WaitingForCustomer" | "WaitingForSupport";
export type SubService = "BankCard" | "Fraud" | "IpRange" | "None" | "Phishing" | "RelatedUrl" | "Suspicious" | "TaxpayerId";
export type TimeInterval = "Long" | "String";
export type Tonality = "Negative" | "Neutral" | "Positive" | "Unset";
export type UpsertNotificationSubscriptionInput = {
  emailPeriod?: string | null;
  id?: any | null;
  isActive?: boolean | null;
  name?: string | null;
  query?: NotificationSubscriptionQueryInput | null;
  subscriberLogin?: string | null;
};
export type NotificationSubscriptionQueryInput = {
  actor?: UserFilterInput | null;
  entities?: EntitiesByServiceFilterInput | null;
  entitiesAll?: ServiceEntityFilterInput | null;
  events?: AllEventsSelectorInput | null;
};
export type UserFilterInput = {
  login?: StringIncludeExcludeFilterInput | null;
  organization?: StringIncludeExcludeFilterInput | null;
};
export type StringIncludeExcludeFilterInput = {
  exclude?: ReadonlyArray<string> | null;
  include?: ReadonlyArray<string> | null;
};
export type AllEventsSelectorInput = {
  attachmentAdded?: AttachmentAddedHistoryEventFilterInput | null;
  authFailure?: AuthFailureHistoryEventFilterInput | null;
  authSuccess?: AuthSuccessHistoryEventFilterInput | null;
  clientRequest?: ClientRequestHistoryEventFilterInput | null;
  commentAdded?: CommentAddedHistoryEventFilterInput | null;
  created?: CreatedHistoryEventFilterInput | null;
  hiddenChanged?: HiddenHistoryEventFilterInput | null;
  leakCollectionAdded?: LeakCollectionAddedHistoryEventFilterInput | null;
  logout?: LogoutHistoryEventFilterInput | null;
  reportGenerated?: ReportGeneratedHistoryEventFilterInput | null;
  stateChanged?: StateChangedHistoryEventFilterInput | null;
};
export type CreatedHistoryEventFilterInput = {
  include?: boolean | null;
};
export type StateChangedHistoryEventFilterInput = {
  from?: ReadonlyArray<State> | null;
  to?: ReadonlyArray<State> | null;
};
export type HiddenHistoryEventFilterInput = {
  hidden?: boolean | null;
};
export type ClientRequestHistoryEventFilterInput = {
  include?: boolean | null;
};
export type CommentAddedHistoryEventFilterInput = {
  include?: boolean | null;
};
export type LeakCollectionAddedHistoryEventFilterInput = {
  include?: boolean | null;
};
export type AttachmentAddedHistoryEventFilterInput = {
  include?: boolean | null;
};
export type AuthSuccessHistoryEventFilterInput = {
  include?: boolean | null;
};
export type AuthFailureHistoryEventFilterInput = {
  include?: boolean | null;
};
export type LogoutHistoryEventFilterInput = {
  include?: boolean | null;
};
export type ReportGeneratedHistoryEventFilterInput = {
  include?: boolean | null;
};
export type EntitiesByServiceFilterInput = {
  database?: DatabaseFilterInput | null;
  fraudulentResource?: FraudulentFilterInput | null;
  limitedAccess?: LimitedAccessFilterInput | null;
  massMedia?: MassMediaFilterInput | null;
  mobileApplication?: MobileApplicationFilterInput | null;
  pilot?: PilotFilterInput | null;
  sharingPlatform?: SharingPlatformFilterInput | null;
  socialAccount?: SocialAccountFilterInput | null;
  socialMedia?: SocialMediaFilterInput | null;
};
export type MassMediaFilterInput = {
  clientTags?: StringAndOrQueryInput | null;
  created?: LongFromToFilterInput | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<any> | null;
  link?: string | null;
  organization?: StringIncludeExcludeFilterInput | null;
  priority?: ReadonlyArray<Priority> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: StringAndOrQueryInput | null;
  tonality?: ReadonlyArray<Tonality> | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type StringAndOrQueryInput = {
  and?: ReadonlyArray<string> | null;
  nand?: ReadonlyArray<string> | null;
  nor?: ReadonlyArray<string> | null;
  or?: ReadonlyArray<string> | null;
};
export type LongFromToFilterInput = {
  from?: any | null;
  to?: any | null;
};
export type SocialMediaFilterInput = {
  clientTags?: StringAndOrQueryInput | null;
  created?: LongFromToFilterInput | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<any> | null;
  link?: string | null;
  organization?: StringIncludeExcludeFilterInput | null;
  priority?: ReadonlyArray<Priority> | null;
  sourceName?: ReadonlyArray<string> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: StringAndOrQueryInput | null;
  tonality?: ReadonlyArray<Tonality> | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type MobileApplicationFilterInput = {
  clientTags?: StringAndOrQueryInput | null;
  created?: LongFromToFilterInput | null;
  detectReason?: string | null;
  fileName?: string | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<any> | null;
  malware?: boolean | null;
  md5?: string | null;
  organization?: StringIncludeExcludeFilterInput | null;
  priority?: ReadonlyArray<Priority> | null;
  ruleName?: string | null;
  sha1?: string | null;
  sha256?: string | null;
  sourceName?: ReadonlyArray<string> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: StringAndOrQueryInput | null;
  threatName?: StringAndOrQueryInput | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type SocialAccountFilterInput = {
  clientTags?: StringAndOrQueryInput | null;
  created?: LongFromToFilterInput | null;
  detectReason?: string | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<any> | null;
  organization?: StringIncludeExcludeFilterInput | null;
  priority?: ReadonlyArray<Priority> | null;
  ruleName?: string | null;
  sourceName?: ReadonlyArray<string> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: StringAndOrQueryInput | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type FraudulentFilterInput = {
  child?: FraudulentChildFilterInput | null;
  clientTags?: StringAndOrQueryInput | null;
  created?: LongFromToFilterInput | null;
  detectReason?: string | null;
  domainZone?: string | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<any> | null;
  organization?: StringIncludeExcludeFilterInput | null;
  priority?: ReadonlyArray<Priority> | null;
  ruleName?: string | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  subService?: ReadonlyArray<SubService> | null;
  tags?: StringAndOrQueryInput | null;
  threatName?: StringAndOrQueryInput | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type FraudulentChildFilterInput = {
  clientTags?: StringAndOrQueryInput | null;
  created?: LongFromToFilterInput | null;
  detectReason?: string | null;
  domainZone?: string | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<any> | null;
  priority?: ReadonlyArray<Priority> | null;
  ruleName?: string | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  subService?: ReadonlyArray<SubService> | null;
  tags?: StringAndOrQueryInput | null;
  threatName?: StringAndOrQueryInput | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type DatabaseFilterInput = {
  cardHolder?: string | null;
  cardNumber?: string | null;
  clientTags?: StringAndOrQueryInput | null;
  collectionId?: string | null;
  collectionName?: string | null;
  created?: LongFromToFilterInput | null;
  firstName?: string | null;
  fullName?: string | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<any> | null;
  ip?: string | null;
  lastName?: string | null;
  leakedDate?: DateFromToFilterInput | null;
  leakedDateDayStart?: LongFromToFilterInput | null;
  organization?: StringIncludeExcludeFilterInput | null;
  password?: string | null;
  passwordHash?: string | null;
  phone?: string | null;
  priority?: ReadonlyArray<Priority> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: StringAndOrQueryInput | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type DateFromToFilterInput = {
  from?: any | null;
  to?: any | null;
};
export type LimitedAccessFilterInput = {
  clientTags?: StringAndOrQueryInput | null;
  created?: LongFromToFilterInput | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<any> | null;
  organization?: StringIncludeExcludeFilterInput | null;
  priority?: ReadonlyArray<Priority> | null;
  sourceName?: ReadonlyArray<string> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: StringAndOrQueryInput | null;
  telegramId?: string | null;
  telegramNick?: string | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type SharingPlatformFilterInput = {
  clientTags?: StringAndOrQueryInput | null;
  created?: LongFromToFilterInput | null;
  detectReason?: string | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<any> | null;
  organization?: StringIncludeExcludeFilterInput | null;
  priority?: ReadonlyArray<Priority> | null;
  sourceName?: ReadonlyArray<string> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: StringAndOrQueryInput | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type PilotFilterInput = {
  clientTags?: StringAndOrQueryInput | null;
  created?: LongFromToFilterInput | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<any> | null;
  link?: string | null;
  organization?: StringIncludeExcludeFilterInput | null;
  priority?: ReadonlyArray<Priority> | null;
  sourceName?: ReadonlyArray<string> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  subService?: ReadonlyArray<SubService> | null;
  tags?: StringAndOrQueryInput | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type ServiceEntityFilterInput = {
  clientTags?: StringAndOrQueryInput | null;
  created?: LongFromToFilterInput | null;
  hidden?: boolean | null;
  ids?: ReadonlyArray<any> | null;
  organization?: StringIncludeExcludeFilterInput | null;
  priority?: ReadonlyArray<Priority> | null;
  services?: ReadonlyArray<Service> | null;
  sources?: ReadonlyArray<EntitySource> | null;
  states?: ReadonlyArray<State> | null;
  tags?: StringAndOrQueryInput | null;
  tool?: string | null;
  updated?: LongFromToFilterInput | null;
  value?: ReadonlyArray<string> | null;
  valueRegex?: string | null;
};
export type UpsertSubscriptionMutation$variables = {
  data: ReadonlyArray<UpsertNotificationSubscriptionInput>;
};
export type UpsertSubscriptionMutation$data = {
  readonly upsertNotificationSubscriptions: {
    readonly data: ReadonlyArray<{
      readonly emailPeriod: TimeInterval;
      readonly id: any;
      readonly isActive: boolean;
      readonly name: string;
      readonly query: {
        readonly actor: {
          readonly organization: {
            readonly exclude: ReadonlyArray<string> | null;
            readonly include: ReadonlyArray<string> | null;
          } | null;
        } | null;
        readonly entitiesAll: {
          readonly services: ReadonlyArray<Service> | null;
        } | null;
        readonly events: {
          readonly clientRequest: {
            readonly include: boolean | null;
          } | null;
          readonly commentAdded: {
            readonly include: boolean | null;
          } | null;
          readonly created: {
            readonly include: boolean | null;
          } | null;
          readonly leakCollectionAdded: {
            readonly include: boolean | null;
          } | null;
          readonly stateChanged: {
            readonly from: ReadonlyArray<State> | null;
            readonly to: ReadonlyArray<State> | null;
          } | null;
        } | null;
      };
    }>;
    readonly rejections: ReadonlyArray<{
      readonly key: {
        readonly id: any | null;
        readonly value: string | null;
      };
      readonly rejections: ReadonlyArray<{
        readonly id: string;
        readonly message: string;
      }>;
    }>;
  } | null;
};
export type UpsertSubscriptionMutation = {
  response: UpsertSubscriptionMutation$data;
  variables: UpsertSubscriptionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "data"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "include",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "data",
        "variableName": "data"
      }
    ],
    "concreteType": "NotificationSubscriptionAdditionResult",
    "kind": "LinkedField",
    "name": "upsertNotificationSubscriptions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NotificationSubscription",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isActive",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emailPeriod",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "NotificationSubscriptionQuery",
            "kind": "LinkedField",
            "name": "query",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserFilter",
                "kind": "LinkedField",
                "name": "actor",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StringIncludeExcludeFilter",
                    "kind": "LinkedField",
                    "name": "organization",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "exclude",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AllEventsSelector",
                "kind": "LinkedField",
                "name": "events",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CreatedHistoryEventFilter",
                    "kind": "LinkedField",
                    "name": "created",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "StateChangedHistoryEventFilter",
                    "kind": "LinkedField",
                    "name": "stateChanged",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "from",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "to",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CommentAddedHistoryEventFilter",
                    "kind": "LinkedField",
                    "name": "commentAdded",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LeakCollectionAddedHistoryEventFilter",
                    "kind": "LinkedField",
                    "name": "leakCollectionAdded",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClientRequestHistoryEventFilter",
                    "kind": "LinkedField",
                    "name": "clientRequest",
                    "plural": false,
                    "selections": (v3/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ServiceEntityFilter",
                "kind": "LinkedField",
                "name": "entitiesAll",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "services",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Rejection",
        "kind": "LinkedField",
        "name": "rejections",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "RejectionKey",
            "kind": "LinkedField",
            "name": "key",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RejectionMessage",
            "kind": "LinkedField",
            "name": "rejections",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpsertSubscriptionMutation",
    "selections": (v4/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpsertSubscriptionMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "c6e2cb4dd580200474388e7be56f90eb",
    "id": null,
    "metadata": {},
    "name": "UpsertSubscriptionMutation",
    "operationKind": "mutation",
    "text": "mutation UpsertSubscriptionMutation(\n  $data: [UpsertNotificationSubscriptionInput!]!\n) {\n  upsertNotificationSubscriptions(data: $data) {\n    data {\n      id\n      name\n      isActive\n      emailPeriod\n      query {\n        actor {\n          organization {\n            include\n            exclude\n          }\n        }\n        events {\n          created {\n            include\n          }\n          stateChanged {\n            from\n            to\n          }\n          commentAdded {\n            include\n          }\n          leakCollectionAdded {\n            include\n          }\n          clientRequest {\n            include\n          }\n        }\n        entitiesAll {\n          services\n        }\n      }\n    }\n    rejections {\n      key {\n        id\n        value\n      }\n      rejections {\n        id\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "84c1ddf0a8a57dc7a2f11564adc54479";

export default node;
