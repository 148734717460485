import { DateTime, Duration } from 'luxon';

import { graphql } from 'babel-plugin-relay/macro';

import {
  DateOrderType,
  DateOrder,
  DateResponse,
  FromToType,
  IntervalResponse,
  Service,
  ServicePages,
  Services,
  SubService,
  SubServices,
} from '#Models/common';
import {
  RecordProxy,
  RecordSourceSelectorProxy,
  Variables,
} from 'relay-runtime';
import ConnectionHandler from 'relay-connection-handler-plus';
import { difference, groupBy } from 'lodash';
import { FilterName } from '#Components/Filters/models';

export const prepareServices = (
  type: ServicePages,
  current: string | null,
): { services: Services[]; subServices?: SubServices[] } => {
  if (current) {
    if (Object.values(Services).includes(current as unknown as Services)) {
      return { services: [current as Services] };
    }
    if (
      Object.values(SubServices).includes(current as unknown as SubServices)
    ) {
      return {
        services: [Services.FraudulentResource],
        subServices: [current as SubServices],
      };
    }
  }

  switch (type) {
    case ServicePages.Leaks:
      return {
        services: [
          Services.Database,
          Services.LimitedAccess,
          Services.SharingPlatform,
        ],
      };
    case ServicePages.Resources:
      return {
        services: [Services.FraudulentResource],
        subServices: [
          SubServices.Suspicious,
          SubServices.Phishing,
          SubServices.Fraud,
        ],
      };
    case ServicePages.Links:
      return {
        services: [Services.MobileApplication, Services.SocialAccount],
      };
    case ServicePages.Media:
      return {
        services: [Services.MassMedia, Services.SocialMedia],
      };
    default:
      return { services: [] };
  }
};

export const entityMetaFragment = graphql`
  fragment commonsMetaFragment on ServiceEntity {
    hidden
    attachments {
      count
    }
    organization {
      name
    }
    comments {
      count
    }
  }
`;

export const entityInfoFragment = graphql`
  fragment commonsInfoFragment on ServiceEntity {
    id
    value
    description
    created(format: Timestamp)
    updated(format: Timestamp)
    tags
    state
  }
`;

export const serviceEntityFragment = graphql`
  fragment commonsServiceEntityFragment on ServiceEntity {
    id
    value
    description
    created(format: Timestamp)
    updated(format: Timestamp)
    tags
    state
    priority
    ... on Database {
      dataLeaks {
        leaked
      }
    }
  }
`;

export const formatTime = (created: DateResponse): string => {
  const createdDate =
    typeof created === 'string'
      ? DateTime.fromISO(created).setLocale('ru')
      : DateTime.fromMillis(Number(created) * 1000).setLocale('ru');

  const now = DateTime.now();

  // Если время создания было сегодня
  if (now.hasSame(createdDate, 'day')) {
    return createdDate.toFormat('Сегодня, HH:mm');
  }

  // Если время создания было в этом году
  if (now.hasSame(createdDate, 'year')) {
    return createdDate.toFormat('dd LLL, HH:mm');
  }

  // Если время создания было в прошлом году
  return createdDate.toFormat('dd LLL yyyy, HH:mm');
};

export const timeDelay = (timeDuration: IntervalResponse): string => {
  const duration = Duration.fromISO(timeDuration).toObject();

  const hours = duration?.hours ?? 0;
  const minutes = duration?.minutes ?? 0;
  const seconds = duration?.seconds ?? 0;

  const timeParts: string[] = [];

  const totalHours = hours + (duration?.days ?? 0) * 24;

  if (totalHours > 72) {
    // Если больше 72 часов, считаем дни и оставшиеся часы
    const days = Math.floor(totalHours / 24);
    const remainingHours = totalHours % 24;
    return `${days} д. ${remainingHours} ч.`;
  } else {
    if (hours > 0) {
      timeParts.push(`${hours} ч.`);
    }

    if (minutes > 0) {
      timeParts.push(`${minutes} м.`);
    }

    if (seconds > 0) {
      timeParts.push(`${seconds} с.`);
    }
  }

  return timeParts.slice(0, 2).join(' ').trim();
};

export const getSourceText = (source: string | undefined) => {
  switch (source) {
    case 'Bizone':
      return 'BIZONE';
    case 'BizoneAndClient':
      return 'BIZONE + Client';
    case 'Client':
      return 'Client';
    default:
      return '—';
  }
};

export const createAndOrValue = (
  values: (string | null)[] | null | undefined,
  operand: 'and' | 'or' | 'nand' | 'nor' | null | undefined | string = 'or',
): {
  and?: ReadonlyArray<string>;
  or?: ReadonlyArray<string>;
  nand?: ReadonlyArray<string>;
  nor?: ReadonlyArray<string>;
} | null => {
  if (values?.length && operand) {
    return {
      [operand]: values,
    };
  }
  return null;
};

export const createSort = (
  field: typeof FromToType[keyof typeof FromToType] | null,
  order?: DateOrderType | null,
): { order: 'Asc' | 'Desc'; field: 'Created' | 'Updated' } => ({
  order: order === DateOrder.Asc ? 'Asc' : 'Desc',
  field: field === FromToType.Updated ? 'Updated' : 'Created',
});

const checkBaseFilters =
  (variables: any) =>
    (connectionArguments: Variables): boolean =>
      ['hidden', 'states', 'priority', 'tool', 'organization'].reduce(
        (prev, filterName) => {
          const res = (() => {
            if (connectionArguments[filterName]) {
              switch (filterName) {
                case 'hidden':
                case 'priority':
                case 'tool':
                case 'organization':
                  return (
                    connectionArguments[filterName] === variables[filterName]
                  );
                case 'states':
                  if (variables['states']) {
                    return connectionArguments[filterName].every(
                      (state: string) => variables['states'].includes(state),
                    );
                  }
                  return connectionArguments[filterName].includes(
                    variables['state'],
                  );
                default:
                  return true;
              }
            }
            return true;
          })();
          return res && prev;
        },
        true,
      );

const checkArgs = (
  filterNames: typeof FilterName[keyof typeof FilterName][],
  creationVariables: any,
  connectionArguments: any,
): boolean =>
  filterNames.reduce((prev, filterName) => {
    if (
      filterName === FilterName.SubService &&
      connectionArguments[FilterName.SubService]?.length
    ) {
      return (
        connectionArguments[FilterName.SubService].length === 3 ||
        (connectionArguments[FilterName.SubService].length === 1 &&
          connectionArguments[FilterName.SubService][0] ===
          SubService.Suspicious) ||
        (connectionArguments[FilterName.SubService].length &&
          connectionArguments[FilterName.SubService].includes(
            creationVariables[filterName],
          ))
      );
    }
    if (connectionArguments[filterName]) {
      if (Array.isArray(connectionArguments[filterName])) {
        if (Array.isArray(creationVariables[filterName])) {
          return (
            prev &&
            difference(
              creationVariables[filterName],
              connectionArguments[filterName],
            ).length === 0
          );
        } else {
          return (
            prev &&
            connectionArguments[filterName].includes(
              creationVariables[filterName],
            )
          );
        }
      }
      return (
        prev &&
        connectionArguments[filterName] === creationVariables[filterName]
      );
    }
    return prev;
  }, true);

export const getFiltersByService = (
  service: typeof Service[keyof typeof Service],
): typeof FilterName[keyof typeof FilterName][] => {
  switch (service) {
    case Service.FraudulentResource:
      return [
        FilterName.SubService,
        FilterName.RuleName,
        FilterName.DetectReason,
        FilterName.DomainZone,
      ];
    case Service.MassMedia:
      return [FilterName.Link, FilterName.Tonality];
    case Service.SocialMedia:
      return [FilterName.Link, FilterName.Tonality, FilterName.SourceName];
    case Service.MobileApplication:
      return [
        FilterName.RuleName,
        FilterName.DetectReason,
        FilterName.Malware,
        FilterName.FileName,
        FilterName.MD5,
        FilterName.SHA1,
        FilterName.SHA256,
        FilterName.SourceName,
      ];
    case Service.SocialAccount:
      return [
        FilterName.RuleName,
        FilterName.DetectReason,
        FilterName.SourceName,
      ];
    case Service.Database:
      return [
        FilterName.CollectionName,
        FilterName.Password,
        FilterName.PasswordHash,
        FilterName.FirstName,
        FilterName.LastName,
        FilterName.FullName,
        FilterName.Phone,
        FilterName.IP,
        FilterName.CardHolder,
        FilterName.CardNumber,
        FilterName.LeakedDate,
      ];
    case Service.LimitedAccess:
      return [
        FilterName.TelegramNick,
        FilterName.TelegramId,
        FilterName.SourceName,
      ];
    case Service.SharingPlatform:
      return [FilterName.ServiceName, FilterName.SourceName];
    default:
      return [];
  }
};

const checkByServiceFilters =
  (creationVariables: any, service: typeof Service[keyof typeof Service]) =>
    (connectionArguments: Variables): boolean => {
      const filters = getFiltersByService(service);
      return checkArgs(filters, creationVariables, connectionArguments);
    };

export const getFieldNameByService = (
  service: typeof Service[keyof typeof Service],
): string => {
  switch (service) {
    case Service.Database:
      return 'database';
    case Service.FraudulentResource:
      return 'fraudulentResource';
    case Service.LimitedAccess:
      return 'limitedAccess';
    case Service.MassMedia:
      return 'massMedia';
    case Service.MobileApplication:
      return 'mobileApplication';
    case Service.Pilot:
      return 'pilot';
    case Service.SharingPlatform:
      return 'sharingPlatform';
    case Service.SocialAccount:
      return 'socialAccount';
    case Service.SocialMedia:
      return 'socialMedia';
    case Service.Pilot:
      return 'pilot';
  }
};

const getConnectionName = (
  service?: typeof Service[keyof typeof Service],
): string => {
  if (service) {
    return `EntitiesByServiceConnection__${getFieldNameByService(service)}`;
  }
  return 'EntitiesConnectionAll__entitiesAllPaged';
};

export const getActualConnections = (
  store: RecordSourceSelectorProxy,
  filters: any,
  variables: any,
): RecordProxy<{}>[] => {
  const root = store.getRoot();

  const {
    hidden,
    organization,
    priority,
    service,
    sources,
    states,
    tool,
    created,
    updated,
    sort,
    valueRegex,
  } = filters;

  if (service === Service.FraudulentResource) {
    return ConnectionHandler.getConnections(
      root,
      'Resources__entitiesUnionPaged',
      (v) => {
        const checkBaseResult = checkBaseFilters(variables)(v);
        const checkByServiceResult = checkByServiceFilters(
          variables,
          service,
        )(v);
        return checkBaseResult && checkByServiceResult;
      },
    );
  }

  if (service) {
    const connectionParent = root.getLinkedRecord('entitiesPaged', {
      created,
      updated,
      hidden,
      states,
      sources,
      priority,
      tool,
      sort,
      organization,
      valueRegex,
    });

    if (connectionParent) {
      return ConnectionHandler.getConnections(
        connectionParent,
        getConnectionName(service),
        (v) => {
          const checkBaseResult = checkBaseFilters(variables)(v);
          const checkByServiceResult = checkByServiceFilters(
            variables,
            service,
          )(v);
          return checkBaseResult && checkByServiceResult;
        },
      );
    }
    return [];
  }

  return ConnectionHandler.getConnections(
    root,
    getConnectionName(service),
    checkBaseFilters(variables),
  );
};

export const updateConnectionsAfterUpsert = ({
  store,
  upsertVariables,
  connectionFilters,
  edges,
}: {
  store: RecordSourceSelectorProxy;
  upsertVariables: any;
  connectionFilters: any;
  edges: any[];
}): void => {
  const connections = getActualConnections(
    store,
    connectionFilters,
    connectionFilters,
  );

  connections.forEach((connection) => {
    const storedEdges = edges.map(
      (edge) => store.get(edge.id) || store.get(edge.__id),
    );

    const { true: actual = [], false: outdated = [] } = groupBy(
      storedEdges,
      (edge) => {
        if (connectionFilters['updated']) {
          const updated = Number(
            edge?.getValue('updated', { format: 'Timestamp' }),
          );
          if (updated > connectionFilters['updated']['to']) return false;
          return (
            updated > connectionFilters['updated']['from'] &&
            updated < connectionFilters['updated']['to']
          );
        }
        return true;
      },
    );

    outdated
      .map((edge) => edge?.getValue('id') as string)
      .forEach((edgeId) => {
        ConnectionHandler.deleteNode(connection, edgeId);
      });

    actual
      .filter((edge) => {
        return Object.keys(upsertVariables).some((name) => {
          switch (name) {
            case 'state':
              if (connectionFilters['states']?.length) {
                return !connectionFilters['states'].includes(
                  edge?.getValue(name),
                );
              }
              return false;

            default:
              if (connectionFilters[name] !== undefined) {
                return Array.isArray(connectionFilters[name])
                  ? !connectionFilters[name].includes(edge?.getValue(name))
                  : edge?.getValue(name) !== connectionFilters[name];
              }
          }
          return false;
        });
      })
      .map((edge) => edge?.getValue('id') as string)
      .forEach((edgeId) => {
        ConnectionHandler.deleteNode(connection, edgeId);
      });
  });
};

export const invalidateConnections = (
  store: RecordSourceSelectorProxy,
  filters: any,
  variables: any,
): void => {
  const root = store.getRoot();

  const { service } = filters;
  const { created, updated, sort, valueRegex, tool, organization, sources } =
    filters;
  const { state, priority, hidden } = variables;

  const filtersGeneral = {
    created,
    updated,
    sources,
    tool,
    sort,
    organization,
    valueRegex,
  };

  const filtersFromVariables = {
    states: [state],
    priority,
    hidden,
  };

  if (service) {
    const connectionParent = root.getLinkedRecord('entitiesPaged', {
      ...filtersGeneral,
      ...filtersFromVariables,
    });

    connectionParent?.invalidateRecord();

    const connectionGeneral = root.getLinkedRecord(
      'entitiesPaged',
      filtersGeneral,
    );
    connectionGeneral?.invalidateRecord();
  }
};

export const updateConnections = ({
  store,
  creationVariables,
  connectionFilters,
  edges,
}: {
  store: RecordSourceSelectorProxy;
  creationVariables: any;
  connectionFilters: any;
  edges: any[];
}) => {
  const connections = getActualConnections(
    store,
    connectionFilters,
    creationVariables,
  );
  const { service, created, updated } = connectionFilters;

  invalidateConnections(store, connectionFilters, creationVariables);

  connections.forEach((connection) => {
    edges
      .map(({ id }) => store.get(id))
      .filter((edge) => {
        if (connectionFilters[FilterName.ValueRegex]) {
          return (edge?.getValue('value') as string).includes(
            connectionFilters[FilterName.ValueRegex],
          );
        }
        return true;
      })
      .filter((edge) => {
        if (updated) {
          const edgeUpdated = Number(
            edge?.getValue('updated', { format: 'Timestamp' }),
          );
          return edgeUpdated > updated.from && edgeUpdated < updated.to;
        }
        if (created) {
          const edgeCreated = Number(
            edge?.getValue('created', { format: 'Timestamp' }),
          );
          return edgeCreated > created.from && edgeCreated < created.to;
        }
        return true;
      })
      .forEach((storedEdge) => {
        if (storedEdge) {
          const storedEdgeId = storedEdge.getDataID();

          //если сущность новая - добавляем в connection
          const alreadyExistEntityIds =
            connection
              ?.getLinkedRecords('edges')
              ?.map((edge) => edge.getLinkedRecord('node'))
              .map((node) => node?.getDataID()) || [];

          const isNewEntity = !alreadyExistEntityIds.includes(storedEdgeId);
          if (isNewEntity) {
            if (service) {
              if (service === Service.FraudulentResource) {
                if (connection.getType() === `ServiceEntityConnection`) {
                  const newEdge = ConnectionHandler.createEdge(
                    store,
                    connection,
                    storedEdge,
                    `${Service.FraudulentResource}Edge`,
                  );
                  ConnectionHandler.insertEdgeBefore(connection, newEdge);
                }
              } else {
                if (
                  connection.getType() === `${storedEdge.getType()}Connection`
                ) {
                  const newEdge = ConnectionHandler.createEdge(
                    store,
                    connection,
                    storedEdge,
                    `${storedEdge.getType()}Edge`,
                  );
                  ConnectionHandler.insertEdgeBefore(connection, newEdge);
                }
              }
            } else {
              const newEdge = ConnectionHandler.createEdge(
                store,
                connection,
                storedEdge,
                'ServiceEntityEdge',
              );
              ConnectionHandler.insertEdgeBefore(connection, newEdge);
            }
          }
        }
      });
  });
};

export const getDeclOfNum = (sum: number, wordForms: string[]) => {
  const n = Math.abs(sum) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) return wordForms[2];
  if (n1 > 1 && n1 < 5) return wordForms[1];
  if (n1 === 1) return wordForms[0];
  return wordForms[2];
};

export const countAll = (data: any) => {
  if (!data || !data.length) return 0;
  return data
    .map((item: any) => item?.counter ?? 0)
    .reduce((all: number, curr: number) => all + curr, 0);
};
