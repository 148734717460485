import { Suspense, useCallback, useState, FC } from 'react';

import qs from 'query-string';
import { QueryParamProvider } from 'use-query-params';
import {
  createSearchParams,
  Outlet,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { Flex, Spinner, Text, Heading } from 'combinezone/core';
import { AsideType } from '#Components/AppAside/consts';
import { ServicePages, ServicePageType } from '#Models/common';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import Controls from '../Components/Controls';
import BASE_QUERY_PARAMS_CONFIG from '../params';
import AppAside from '../../../Components/AppAside';
import { LeaksPageRoot } from '../Pages/Leaks/route';
import { MediaPageRoot } from '../Pages/Media/route';
import { LinksPageRoot } from '../Pages/Links/route';
import { ResourcesPageRoot } from '../Pages/Resources/route';
import {
  SpinnerContentOut,
  WorkspaceLink,
  StyledToggleGroup,
} from './Layout.styles';
import {
  ContentOut,
  ControlsOut,
  NavigationOut,
  PageLayoutOut,
  WorkspaceLayoutIn,
  WorkspaceLayoutOut,
} from './Layout.styles';
import SavedFiltersPanel from '#Features/SavedFilters/SavedFilters_Panel';
import { useServicesMatch } from '#Hooks/useServicesMatch';
import FiltersControls from '#Components/Filters/Filters_Controls';
import { BankCardPageRoot } from '../Pages/Pilot/route';
import { IpRangePageRoot } from '../Pages/IpRange/route';
import { TaxpayerIdPageRoot } from '../Pages/TaxpayerId/route';

export const WorkspaceLayout: FC = () => {
  const [sParams] = useSearchParams();
  const navigate = useNavigate();
  const { isFraud, isLinks, isPilot, isBankCard, isIpRange, isTaxpayerId } =
    useServicesMatch();

  const [activeTab, setActiveTab] = useState(
    isLinks ? ServicePages.Links : ServicePages.Resources,
  );
  const [activeTabPilot, setActiveTabPilot] = useState(
    isBankCard
      ? ServicePages.BankCard
      : isIpRange
        ? ServicePages.IpRange
        : isTaxpayerId ? ServicePages.TaxpayerId : ServicePages.BankCard,
  );

  const handleTabChange = (tab: ServicePageType) => {
    setActiveTab(tab);
  };

  const handleTabChangePilot = (tab: ServicePageType) => {
    setActiveTabPilot(tab);
    switch (tab) {
      case ServicePages.BankCard:
        navigate(BankCardPageRoot);
        break;
      case ServicePages.IpRange:
        navigate(IpRangePageRoot);
        break;
      case ServicePages.TaxpayerId:
        navigate(TaxpayerIdPageRoot);
        break;
    }
  };

  const createSearchString = useCallback(
    (params: URLSearchParams): string =>
      createSearchParams(
        ['from', 'to', 'field', 'order'].reduce((all, sParamName) => {
          if (params.has(sParamName)) {
            // @ts-ignore
            all.push([sParamName, params.get(sParamName)]);
          }
          return all;
        }, []),
      ).toString(),
    [],
  );

  return (
    <QueryParamProvider
      adapter={ReactRouter6Adapter}
      options={{
        searchStringToObject: qs.parse,
        objectToSearchString: qs.stringify,
        removeDefaultsFromUrl: true,
        params: BASE_QUERY_PARAMS_CONFIG,
      }}
    >
      <WorkspaceLayoutOut>
        <NavigationOut>
          <Flex direction="column">
            <WorkspaceLink
              to={{
                pathname:
                  activeTab === ServicePages.Resources
                    ? ResourcesPageRoot
                    : LinksPageRoot,
                search: createSearchString(sParams),
              }}
            >
              <Heading size="md" variant="secondary">
                МОШЕННИЧЕСТВО
              </Heading>
            </WorkspaceLink>
            <WorkspaceLink
              to={{
                pathname: LeaksPageRoot,
                search: createSearchString(sParams),
              }}
            >
              <Heading size="md" variant="secondary">
                УТЕЧКИ
              </Heading>
            </WorkspaceLink>
            <WorkspaceLink
              to={{
                pathname: MediaPageRoot,
                search: createSearchString(sParams),
              }}
            >
              <Heading size="md" variant="secondary">
                ИНФОПОЛЕ
              </Heading>
            </WorkspaceLink>
            <WorkspaceLink
              to={{
                pathname:
                  activeTabPilot === ServicePages.IpRange
                    ? IpRangePageRoot
                    : activeTabPilot === ServicePages.TaxpayerId
                      ? TaxpayerIdPageRoot
                      : BankCardPageRoot,
                search: createSearchString(sParams),
              }}
            >
              <Heading size="md" variant="secondary">
                ПРОЧЕЕ
              </Heading>
            </WorkspaceLink>
          </Flex>
        </NavigationOut>
        <WorkspaceLayoutIn>
          <PageLayoutOut>
            <ControlsOut>
              <Flex gap="16px" alignItems="center">
                {!!isFraud && (
                  <StyledToggleGroup
                    items={[
                      {
                        content: 'Веб-ресурсы',
                        isActive: activeTab === ServicePages.Resources,
                        onClick: () => {
                          handleTabChange(ServicePages.Resources);
                          navigate(ResourcesPageRoot);
                        },
                        testId: 'web',
                        size: 'sm',
                      },
                      {
                        content: 'Другое',
                        isActive: activeTab === ServicePages.Links,
                        onClick: () => {
                          handleTabChange(ServicePages.Links);
                          navigate(LinksPageRoot);
                        },
                        testId: 'other',
                        size: 'sm',
                      },
                    ]}
                  />
                )}
                {!!isPilot && (
                  <StyledToggleGroup
                    items={[
                      {
                        content: 'Банковские карты',
                        isActive: activeTabPilot === ServicePages.BankCard,
                        onClick: () =>
                          handleTabChangePilot(ServicePages.BankCard),
                        testId: 'bank-card',
                        size: 'sm',
                      },
                      {
                        content: 'IP-адреса',
                        isActive: activeTabPilot === ServicePages.IpRange,
                        onClick: () =>
                          handleTabChangePilot(ServicePages.IpRange),
                        testId: 'ip-range',
                        size: 'sm',
                      },
                      {
                        content: 'ООО для продажи',
                        isActive: activeTabPilot === ServicePages.TaxpayerId,
                        onClick: () =>
                          handleTabChangePilot(ServicePages.TaxpayerId),
                        testId: 'taxpayer-id',
                        size: 'sm',
                      },
                    ]}
                  />
                )}
                <FiltersControls />
                <SavedFiltersPanel />
              </Flex>
              <Controls />
            </ControlsOut>
            <ContentOut>
              <Suspense
                fallback={
                  <SpinnerContentOut>
                    <Spinner />
                    <Text>Подождите, пожалуйста, данные обрабатываются...</Text>
                  </SpinnerContentOut>
                }
              >
                <Outlet />
              </Suspense>
            </ContentOut>
          </PageLayoutOut>
        </WorkspaceLayoutIn>
      </WorkspaceLayoutOut>
      <AppAside type={AsideType.Filters} />
    </QueryParamProvider>
  );
};
