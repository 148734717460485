import { FC, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useMutation, useRelayEnvironment } from 'react-relay';

import { UserFilter } from 'combinezone/icons';
import { fetchQuery } from 'react-relay/hooks';
import { useWorkspace } from '#Hooks/useWorkspace';
import { Dropdown, Text, useToast } from 'combinezone/core';
import { useAccount } from '#Providers/AccountProvider/context';
import { accountQuery } from '#Providers/AccountProvider/AccountPreloader';
import { SavedFiltersMutation } from "#__artifacts/SavedFiltersMutation.graphql";
import { saveFiltersMutation } from "#Features/SavedFilters/SavedFiltersMutation.graphql";
import { SavedFiltersDeleteMutation } from '#__artifacts/SavedFiltersDeleteMutation.graphql';

import { deleteSavedFiltersMutation } from './SavedFiltersDeleteMutation.graphql';
import { DropdownNode, StyledActions, StyledIconButton, StyledNode } from './SavedFilters.styles';

const SavedFilters: FC = () => {
  const { filters, activeFilterId } = useAccount();
  const navigate = useNavigate();
  const { toastSuccess, toastError } = useToast();
  const service = useWorkspace();
  const environment = useRelayEnvironment();

  const [deleteFilter, isInProgress] = useMutation<SavedFiltersDeleteMutation>(
    deleteSavedFiltersMutation,
  );

  const [saveFilters] =
    useMutation<SavedFiltersMutation>(saveFiltersMutation);

  const refetchAccount = useCallback(async (): Promise<void> => {
    try {
      await fetchQuery(environment, accountQuery, {}).toPromise();
    } catch (e) {
      console.warn(e);
    }
  }, [environment]);

  const handleDelete = useCallback(
    (index: number, onSuccess?: () => void) => {
      const { key, timestamp, id } = filters[service][index];
      const fullFilterName = ['filters', service, id, timestamp, key].join('.');
      deleteFilter({
        variables: {
          data: [
            {
              key: fullFilterName,
            },
          ],
        },
        onCompleted: (response, errors) => {
          if (response?.deleteUserSettings) {
            toastSuccess({
              title: 'Фильтр удален',
            });
          }
          onSuccess?.();
          refetchAccount();
        },
        onError: () => {
          toastError({
            title: 'Что-то пошло не так...',
            message: 'Повторите попытку позже',
          });
        },
      });
    },
    [service, deleteFilter, refetchAccount, toastSuccess, toastError, filters],
  );

  const handleSave = useCallback((id: string) => {
    saveFilters({
      variables: {
        data: [
          {
            key: ['activeFilterId', service].join('.'),
            value: id,
          }
        ],
      },
      onCompleted: (response, errors) => {
        refetchAccount();
      },
    });
  }, [refetchAccount, service]);

  return (
    <Dropdown
      position="bottom-right"
      usePortal={false}
      content={({ onClose }) => (
        <div style={{ width: 366 }}>
          <StyledNode key="filters" testId="filters" isDisabled>
            <Text isParagraph variant="secondary" fontWeight="medium">
              СОХРАНЕННЫЕ ФИЛЬТРЫ
            </Text>
          </StyledNode>
          {filters[service].length === 0 ? (
            <StyledNode testId="nofilters">
              <Text variant="secondary">Нет сохраненных фильтров</Text>
            </StyledNode>
          ) : (
            filters[service].map(({ key, value, id }, index) => {
              return (
                <DropdownNode
                  key={key}
                  testId={key}
                  onClick={() => {
                    handleSave(id);
                    navigate(value);
                    onClose();
                  }}
                  accent={activeFilterId[service] === id ? 'active' : 'default'}
                >
                  <Text style={{ wordBreak: 'break-word' }}>{key}</Text>
                  <StyledActions
                    items={[
                      {
                        action: () => {
                          handleDelete(index);
                        },
                        isDelete: true,
                        testId: 'delete',
                        tooltip: {
                          isOpen: false,
                          content: null,
                        },
                        confirmMessage: '',
                        onAsk: () => { },
                        isDisabled: isInProgress,
                      },
                    ]}
                    testId=""
                  />
                </DropdownNode>
              );
            })
          )}
        </div>
      )}
    >
      <StyledIconButton
        icon={UserFilter}
        tooltip={null}
        testId="ShowSavedFilters"
        tabIndex={100}
      />
    </Dropdown>
  );
};

export default observer(SavedFilters);
