import { DateTime } from 'luxon';

import { Text } from 'combinezone/core';
import { GridColumn } from 'combinezone/datagrid';
import { EventLog } from '#Modules/EventLog/types';

import { TextValue } from './EventLogGrid.styles';
import { eventType } from '../EventTypeFilter/EventTypeFilter';

type RowData = Record<string, unknown> & EventLog;

export const EVENT_LOG_GRID_COLUMNS: GridColumn<RowData>[] = [
  {
    accessor: 'date',
    Header: 'Дата',
    CellRenderer: ({
      cell: {
        row: { original },
      },
    }) => (
      <Text>
        {DateTime.fromSeconds(Number(original.node?.date))
          .setLocale('ru')
          .toFormat('dd MMM yyyy, HH:mm')}
      </Text>
    ),
    width: 240,
  },
  {
    accessor: 'login',
    HeaderRenderer: () => (
      <Text variant="secondary" isClipped={false}>
        Пользователь
      </Text>
    ),
    CellRenderer: ({
      cell: {
        row: { original },
      },
    }) => <Text>{original.node?.actor?.login}</Text>,
    width: 240,
  },
  {
    accessor: 'ip',
    HeaderRenderer: () => (
      <Text variant="secondary" isClipped={false}>
        IP
      </Text>
    ),
    CellRenderer: ({
      cell: {
        row: { original },
      },
    }) => {
      const ip = (
        original.node?.authSuccess ||
        original.node?.authFailure ||
        original.node?.logout ||
        ''
      )
        .trim()
        .replace(/^\//, '');
      return <Text>{ip}</Text>;
    },
    width: 180,
  },
  {
    accessor: 'event',
    HeaderRenderer: () => (
      <Text variant="secondary" isClipped={false}>
        Тип события
      </Text>
    ),
    CellRenderer: ({
      cell: {
        row: { original },
      },
    }) => (
      <Text isClipped={false}>
        {original.node?.commentAdded
          ? eventType.commentAdded
          : original.node?.attachmentAdded
            ? eventType.attachmentAdded
            : original.node?.stateChanged
              ? eventType.stateChanged
              : original.node?.leakCollectionAdded
                ? eventType.leakCollectionAdded
                : original.node?.reportGenerated
                  ? eventType.reportGenerated
                  : original.node?.authSuccess
                    ? eventType.authSuccess
                    : original.node?.authFailure
                      ? eventType.authFailure
                      : original.node?.logout
                        ? eventType.logout
                        : original.node?.reportGenerated
                          ? eventType.reportGenerated
                          : original.node?.clientRequest
                            ? eventType.clientRequest
                            : original.node && typeof original.node.hiddenChanged === 'boolean'
                              ? eventType.hiddenChanged
                              : eventType.created}
      </Text>
    ),
    width: 200,
  },
  {
    accessor: 'details',
    HeaderRenderer: () => (
      <Text variant="secondary" isClipped={false}>
        Детали
      </Text>
    ),
    CellRenderer: ({
      cell: {
        row: { original },
      },
    }) => (
      <Text isClipped={false}>
        {original.node?.commentAdded ? (
          <>
            <Text variant="secondary">Сообщение: </Text>
            <Text>{original.node.commentAdded ?? '-'}</Text>
            <br />

            <Text variant="secondary">Объект: </Text>
            <TextValue>{original.node.entity?.value}</TextValue>
          </>
        ) : original.node?.attachmentAdded ? (
          <>
            <Text variant="secondary">Файл: </Text>
            <TextValue>{original.node?.attachmentAdded}</TextValue>
            <br />
            <Text variant="secondary">Объект: </Text>
            <TextValue>{original.node.entity?.value}</TextValue>
          </>
        ) : original.node?.stateChanged ? (
          <>
            <Text variant="secondary">на: </Text>
            <Text>{original.node.stateChanged}</Text>
            <br />
            <Text variant="secondary">Объект: </Text>
            <TextValue>{original.node.entity?.value}</TextValue>
          </>
        ) : original.node?.leakCollectionAdded ? (
          <>
            <Text variant="secondary">База данных: </Text>
            <Text>{original.node.leakCollectionAdded}</Text>
            <br />
            <Text variant="secondary">Объект: </Text>
            <TextValue>{original.node.entity?.value}</TextValue>
          </>
        ) : original.node?.reportGenerated ? (
          <Text>{original.node.reportGenerated}</Text>
        ) : original.node &&
          typeof original.node.hiddenChanged === 'boolean' ? (
          <>
            <Text variant="secondary">на: </Text>
            <Text>
              {original.node.hiddenChanged ? 'Ограничена' : 'Разрешена'}
            </Text>
            <br />
            <Text variant="secondary">Объект: </Text>
            <TextValue>{original.node.entity?.value}</TextValue>
          </>
        ) : (
          <TextValue>{original.node?.created?.value ?? ''}</TextValue>
        )}
      </Text>
    ),
  },
];
