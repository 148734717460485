import { FC, useMemo } from 'react';

import { observer } from 'mobx-react-lite';
import { useQueryParams } from 'use-query-params';
import { ErrorBoundary } from 'react-error-boundary';

import { useLazyLoadQuery } from 'react-relay/hooks';
import EVENT_LOG_PARAMS_CONFIG from '#Modules/EventLog/params';
import { useAccount } from '#Providers/AccountProvider/context';
import { EventLogLoaderQuery } from '#__artifacts/EventLogLoaderQuery.graphql';

import { queryEventLog } from './EventLogLoader.graphql';
import { excludedUsers } from '../SystemEvents/constants';
import EventLogConnection from '../Connection/EventLogConnection';
import EntitiesNotFound from '../../../Services/Components/Entities/NotFound';

const eventsQueryDefault = {
    created: {},
    stateChanged: {},
    clientRequest: {},
    commentAdded: {},
    attachmentAdded: {},
    leakCollectionAdded: {},
    hiddenChanged: {},
    authSuccess: {},
    authFailure: {},
    logout: {},
    reportGenerated: {},
};

const {
    leakCollectionAdded,
    hiddenChanged,
    ...eventsQueryWithoutLeakAndHidden
} = eventsQueryDefault;

const EventLogLoaderContainer: FC<{
    setShowFilter: (value: boolean) => void;
}> = ({ setShowFilter }) => {
    const { account, isAdmin, selectedOrganization: organization } = useAccount();

    const [params] = useQueryParams<typeof EVENT_LOG_PARAMS_CONFIG>();
    const { events, user, showSysEvents } = params;

    const variables = useMemo(() => {
        const eventsSelected = events && Object.fromEntries(events.map(type => [type, {}]));

        const eventsFilter = events?.length
            ? eventsSelected
            : isAdmin
                ? eventsQueryDefault
                : eventsQueryWithoutLeakAndHidden;

        let userFilter = null;

        if (!user?.length && !showSysEvents && isAdmin && !organization) {
            userFilter = { login: { exclude: excludedUsers } };
        } else if (user?.length) {
            userFilter = { login: { include: [user] } };
        }

        const organizationFilter = isAdmin && organization
            ? { organization: { include: [organization] } }
            : !isAdmin && account?.organization?.name
                ? { organization: { include: [account.organization.name] } }
                : {};

        return {
            events: eventsFilter,
            actor: {
                ...userFilter,
                ...organizationFilter,
            },
        };
    }, [account, events, isAdmin, organization, user, showSysEvents, excludedUsers]);

    const queryRef = useLazyLoadQuery<EventLogLoaderQuery>(
        queryEventLog,
        variables,
        {
            fetchPolicy: 'store-and-network',
        },
    );

    return (
        <ErrorBoundary fallback={<EntitiesNotFound />}>
            <EventLogConnection
                queryRef={queryRef}
                setShowFilter={setShowFilter}
                params={params}
            />
        </ErrorBoundary>
    );
};

export default observer(EventLogLoaderContainer);
EventLogLoaderContainer.displayName = 'EventLogLoaderContainer';
