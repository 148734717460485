import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { Heading, TextWrapper, ToggleGroup } from 'combinezone/core';

export const WorkspaceLayoutOut = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: stretch;
`;

export const WorkspaceLayoutIn = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const PageLayoutOut = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 56px auto 1fr;
  gap: 0;
  grid-template-areas:
    'controls'
    'factoids'
    'content';
  width: 100%;
  height: 100%;
`;

export const PageTabsOut = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PageTabLink = styled(NavLink)`
  display: flex;
  width: fit-content;
  height: 56px;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
  color: ${({ theme }) => theme.basis.colors.text.default};
  font-size: 14px;
  font-family: 'Roboto';

  &.active:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background: ${({ theme }) => theme.components.tab.colors.active};
    bottom: 0;
    left: 0;
  }
`;

export const NavigationOut = styled.div`
  display: flex;
  flex-direction: column;
  width: 80px;
  height: 100%;
  z-index: 1;
  background: ${({ theme }) => theme.components.layout.colors.sidebar};
  border-right: 2px solid
    ${({ theme }) => theme.components.card.colors.border.normal};
`;

export const ControlsOut = styled.div`
  grid-area: controls;
  z-index: 0;
  background: ${({ theme }) => theme.components.layout.colors.toolbar};
  border-bottom: 1px solid
    ${({ theme }) => theme.components.card.colors.border.normal};
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  height: 56px;
`;

export const ContentOut = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  margin: 0 16px;
  padding: 16px 0;
  box-sizing: border-box;
`;

export const SpinnerContentOut = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 320px 16px 16px 16px;
  gap: 24px;
  align-items: center;
  width: 100%;
  overflow: hidden;
`;

export const WorkspaceLink = styled(NavLink)`
  display: flex;
  width: 80px;
  text-decoration: none;
  padding: 24px 0;
  align-items: center;
  justify-content: center;
  position: relative;

  ${Heading} {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    text-decoration: none;
  }

  &.active ${Heading} {
    color: ${({ theme }) => theme.components.tab.colors.active};
  }

  :hover {
    background-color: ${({ theme }) => theme.components.node.colors.hover};
  }

  &.active:before {
    content: '';
    position: absolute;
    width: 4px;
    height: 100%;
    background: ${({ theme }) => theme.components.tab.colors.active};
    top: 0;
    right: 0;
  }
`;

export const StyledToggleGroup = styled(ToggleGroup)`
  button {
    padding: 4px 8px;
    height: 24px;
    --_toggle-button-colors-background-default: ${({ theme }) =>
        theme.isDark ? '#171717' : '#f5f5f5'};
    --_toggle-button-colors-background-hover: ${({ theme }) =>
        theme.isDark ? '#171717' : '#f5f5f5'};
  }
  ${TextWrapper} {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
`;
