import { graphql } from 'babel-plugin-relay/macro';

const PilotPageQuery = graphql`
  query PilotPageQuery(
    $organization: String
    $updated: LongFromToFilterInput
    $created: LongFromToFilterInput
    $subService: [SubService!] 
  ) {
    count {
      ...PilotFragmentCounts
    }
  }
`;

export default PilotPageQuery;