/**
 * @generated SignedSource<<d6694ce79699a3be79d959c0588ab3e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubService = "BankCard" | "Fraud" | "IpRange" | "None" | "Phishing" | "RelatedUrl" | "Suspicious" | "TaxpayerId";
export type LongFromToFilterInput = {
  from?: any | null;
  to?: any | null;
};
export type PilotPageQuery$variables = {
  created?: LongFromToFilterInput | null;
  organization?: string | null;
  subService?: ReadonlyArray<SubService> | null;
  updated?: LongFromToFilterInput | null;
};
export type PilotPageQuery$data = {
  readonly count: {
    readonly " $fragmentSpreads": FragmentRefs<"PilotFragmentCounts">;
  } | null;
};
export type PilotPageQuery = {
  response: PilotPageQuery$data;
  variables: PilotPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "created"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organization"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subService"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "updated"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PilotPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EntitiesByServiceCounters",
        "kind": "LinkedField",
        "name": "count",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PilotFragmentCounts"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Queries",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "PilotPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EntitiesByServiceCounters",
        "kind": "LinkedField",
        "name": "count",
        "plural": false,
        "selections": [
          {
            "alias": "Pilot",
            "args": [
              {
                "kind": "Variable",
                "name": "created",
                "variableName": "created"
              },
              {
                "kind": "Variable",
                "name": "organization",
                "variableName": "organization"
              },
              {
                "kind": "Variable",
                "name": "subService",
                "variableName": "subService"
              },
              {
                "kind": "Variable",
                "name": "updated",
                "variableName": "updated"
              }
            ],
            "concreteType": "PilotCount",
            "kind": "LinkedField",
            "name": "pilot",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "counter",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "subService",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c63889dec9afe16f1c0dd06ed4d6b626",
    "id": null,
    "metadata": {},
    "name": "PilotPageQuery",
    "operationKind": "query",
    "text": "query PilotPageQuery(\n  $organization: String\n  $updated: LongFromToFilterInput\n  $created: LongFromToFilterInput\n  $subService: [SubService!]\n) {\n  count {\n    ...PilotFragmentCounts\n  }\n}\n\nfragment PilotFragmentCounts on EntitiesByServiceCounters {\n  Pilot: pilot(organization: $organization, created: $created, updated: $updated, subService: $subService) {\n    counter\n    subService\n  }\n}\n"
  }
};
})();

(node as any).hash = "1d50d9e224d89f880e6a1bceee528f8e";

export default node;
