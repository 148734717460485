import { StringParam } from 'use-query-params';
import {
    BooleanParam,
    createEnumDelimitedArrayParam,
    withDefault,
} from 'serialize-query-params';

export const eventTypes = [
    'created',
    'stateChanged',
    'clientRequest',
    'commentAdded',
    'attachmentAdded',
    'leakCollectionAdded',
    'hiddenChanged',
    'authSuccess',
    'authFailure',
    'logout',
    'reportGenerated',
];

const EVENT_LOG_PARAMS_CONFIG = {
    events: withDefault(createEnumDelimitedArrayParam(eventTypes), [], false),
    user: withDefault(StringParam, '', false),
    showSysEvents: withDefault(BooleanParam, false),
};

export default EVENT_LOG_PARAMS_CONFIG;
