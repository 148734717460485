import { FC, useEffect } from 'react';

import { useQueryParam } from 'use-query-params';

import { Checkbox, CheckboxProps } from 'combinezone/core';

const SystemEvents: FC<
    CheckboxProps & { shouldResetShowSysEvents: boolean }
> = ({ label, isDisabled, testId, shouldResetShowSysEvents }) => {
    const [showSysEvents, setShowSysEvents] =
        useQueryParam<boolean>('showSysEvents');

    useEffect(() => {
        if (shouldResetShowSysEvents) {
            setShowSysEvents(false);
        }
    }, [shouldResetShowSysEvents, setShowSysEvents]);

    const handleCheckboxClick = () => {
        setShowSysEvents(!showSysEvents);
    };

    return (
        <Checkbox
            testId={testId}
            label={label}
            onClick={handleCheckboxClick}
            isChecked={showSysEvents}
            isDisabled={isDisabled}
        />
    );
};

export default SystemEvents;
SystemEvents.displayName = 'SystemEvents';
