import React from 'react';

import { RouteObject } from 'react-router/dist/lib/context';

import { Workspaces } from '../../../../Models/common';
import ErrorStub from '../../../../Components/ErrorStub';
import SelectionProvider from '../../../../Components/SelectionProvider';

export const TaxpayerIdPageRoot = `${Workspaces.Pilot}/inn`;

const TaxpayerIdPage = React.lazy(() => import('.'));

const TaxpayerIdPageRoute: RouteObject = {
    path: `${TaxpayerIdPageRoot}/*`,
    element: (
        <SelectionProvider>
            <TaxpayerIdPage />
        </SelectionProvider>
    ),
    errorElement: <ErrorStub />,
};

export default TaxpayerIdPageRoute;