import { FC, Suspense, useState } from 'react';

import { observer } from 'mobx-react-lite';
import { useQueryParams } from 'use-query-params';

import { Flex, Spinner, Text } from 'combinezone/core';
import EVENT_LOG_PARAMS_CONFIG from '#Modules/EventLog/params';
import { useAccount } from '#Providers/AccountProvider/context';

import { LogsBlock } from './Logs.styles';
import UserFilter from '../Components/UserFilter';
import SystemEvents from '../Components/SystemEvents';
import EventLogLoader from '../Components/Loader/EventLogLoader';
import EventTypeFilter from '../Components/EventTypeFilter/EventTypeFilter';

const Logs: FC = () => {
  const [params] = useQueryParams<typeof EVENT_LOG_PARAMS_CONFIG>();
  const { user, showSysEvents } = params;

  const { isAdmin, selectedOrganization: organization } = useAccount();

  const [showFilter, setShowFilter] = useState(true);

  const getActions = () => {
    const baseActions = [
      <EventTypeFilter key="EventTypes" placeholder={'Тип события'} />,
      <UserFilter key="UserFilter" placeholder={'Пользователь'} />,
    ];

    if (showFilter && isAdmin) {
      return [
        ...baseActions,
        <SystemEvents
          key="SystemEvents"
          testId={'SystemEvents'}
          label={'Отобразить системные события'}
          isDisabled={
            !!organization || (!!user && !organization && !showSysEvents)
          }
          shouldResetShowSysEvents={!!user || !!organization}
        />,
      ];
    }

    return baseActions;
  };

  const actions = getActions();

  return (
    <LogsBlock
      headerContent={'Журнал событий'}
      testId={'EventLogBlock'}
      isExpandable={false}
      isCollapsible={false}
      isHeaderSeparated
      actions={actions}
      isFullHeight
    >
      <Suspense
        fallback={
          <Flex
            direction="column"
            gap="24px"
            alignItems="center"
            style={{ paddingTop: '240px' }}
          >
            <Spinner />
            <Text>Подождите, пожалуйста, данные обрабатываются...</Text>
          </Flex>
        }
      >
        <EventLogLoader setShowFilter={setShowFilter} />
      </Suspense>
    </LogsBlock>
  );
};

export default observer(Logs);
Logs.displayName = 'Logs';
