import React from 'react';

import { RouteObject } from 'react-router/dist/lib/context';

import { Workspaces } from '../../../../Models/common';
import ErrorStub from '../../../../Components/ErrorStub';
import SelectionProvider from '../../../../Components/SelectionProvider';

export const IpRangePageRoot = `${Workspaces.Pilot}/ip`;

const IpRangePage = React.lazy(() => import('.'));

const IpRangePageRoute: RouteObject = {
    path: `${IpRangePageRoot}/*`,
    element: (
        <SelectionProvider>
            <IpRangePage />
        </SelectionProvider>
    ),
    errorElement: <ErrorStub />,
};

export default IpRangePageRoute;