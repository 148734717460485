
export const rejectionId = {
    entityAlreadyExists: 'entity-already-exists',
    urlFormat: 'invalid-url-format',
    domainFormat: 'invalid-domain-format',
    state: 'invalid-state',
    captionFormat: 'invalid-caption-format',
    bankCardFormat: "invalid-bank-card-format",
    ipRangeFormat: "invalid-ip-range-format",
    uidSuffixFormat: "invalid-uid-suffix-format",
    taxpayerUdFormat: "invalid-taxpayer-id-format"
}