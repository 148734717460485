// @ts-nocheck

import { FC, useMemo } from 'react';
import { FilterName } from '../models';
import { Alert } from 'combinezone/core';
import { useQueryParam } from 'use-query-params';
import {
  createEnumArrayParam,
  createEnumParam,
  withDefault,
} from 'serialize-query-params';
import {
  getStatesByService,
  Service,
  State,
  States,
  SubService,
} from '#Models/common';
import styled from 'styled-components';
import { useServicesMatch } from '#Hooks/useServicesMatch';

const StyledAlert = styled(Alert)`
  min-height: unset;
`;

const FilterStatesExtraAlert: FC = () => {
  const { isResources, isLinks, isLeaks, isPilot } = useServicesMatch();

  const [selectedService] = useQueryParam(
    FilterName.Type,
    withDefault(
      createEnumParam([
        Service.MassMedia,
        Service.SocialMedia,
        Service.MobileApplication,
        Service.SocialAccount,
        SubService.Fraud,
        SubService.Phishing,
        SubService.Suspicious,
        Service.Database,
        Service.LimitedAccess,
        Service.SharingPlatform,
        Service.MassMedia,
        Service.SocialMedia,
      ]),
      undefined,
      false,
    ),
  );
  const [value] = useQueryParam(
    FilterName.States,
    withDefault(createEnumArrayParam(States), undefined, false),
    {
      removeDefaultsFromUrl: true,
      updateType: 'replaceIn',
    },
  );

  const options = useMemo<typeof State[keyof typeof State][]>(() => {
    if (selectedService) {
      return getStatesByService(selectedService).map((state) => state);
    }
    if (isResources) {
      const states = new Set([
        ...getStatesByService(SubService.Fraud),
        ...getStatesByService(SubService.Phishing),
        ...getStatesByService(SubService.Suspicious),
      ]);
      return Array.from(states).map((state) => state);
    }
    if (isLinks) {
      const states = new Set([
        ...getStatesByService(Service.MobileApplication),
        ...getStatesByService(Service.SocialAccount),
      ]);
      return Array.from(states).map((state) => state);
    }
    if (isLeaks) {
      const states = new Set([
        ...getStatesByService(Service.Database),
        ...getStatesByService(Service.SharingPlatform),
        ...getStatesByService(Service.LimitedAccess),
      ]);
      return Array.from(states).map((state) => state);
    }
    if (isPilot) {
      const states = new Set([
        ...getStatesByService(SubService.BankCard),
        ...getStatesByService(SubService.IpRange),
        ...getStatesByService(SubService.TaxpayerId),
      ]);
      return Array.from(states).map((state) => state);
    }
    return [];
  }, [isLeaks, isLinks, isPilot, isResources, selectedService]);

  const extraStates = (value ?? []).reduce((sum, state) => {
    if (!options.includes(state)) sum.push(state);
    return sum;
  }, []);

  if (!extraStates.length) {
    return null;
  }

  return (
    <StyledAlert
      testId="extraStates"
      type="warning"
      title="Недоступный статус для сервиса"
      message="Выберите другой статус"
    />
  );
};

export default FilterStatesExtraAlert;
FilterStatesExtraAlert.displayName = 'FilterStatesExtraAlert';
