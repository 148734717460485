import { FC, memo } from 'react';

import { useQueryParams } from 'use-query-params';
import { useErrorBoundary } from 'react-error-boundary';

import { Button, Factoid, Heading, Spacer, Text } from 'combinezone/core';
import { FilterOff, IllustrationError404 } from 'combinezone/icons';

import { EntitiesNoData, EntitiesNotFoundOut } from './Entities_NotFound.styles';
import { useSearchParams } from 'react-router-dom';

const EntitiesNotFound: FC = () => {
  const { resetBoundary } = useErrorBoundary();
  const [, setParams] = useQueryParams();
  const [params,] = useSearchParams();


  const hasEnabledFilters = Boolean(
    Array.from(params.keys()).filter(
      (name) =>
        !['field', 'from', 'to', 'order', 'tags_operand', 'client_tags_operand'].includes(name),
    ).length,
  );

  return hasEnabledFilters ? (
    <EntitiesNotFoundOut>
      <IllustrationError404 />
      <Spacer height={24} />
      <Factoid size="md">Ничего не найдено</Factoid>
      <Spacer height={8} />
      <Text>Попробуйте уточнить запрос</Text>
      <Spacer height={24} />
      <Button
        testId="FiltersReset"
        LeftIcon={FilterOff}
        onClick={() => {
          resetBoundary();
          setParams({}, 'replace');
        }}
      >
        Сбросить фильтры
      </Button>
    </EntitiesNotFoundOut>
  ) : (
    <EntitiesNoData>
      <Heading variant="secondary">
        Данные отсутствуют
      </Heading>
    </EntitiesNoData>
  );
};

export default memo(EntitiesNotFound);
EntitiesNotFound.displayName = 'EntitiesNotFound';
